import * as React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import theme from '../../gatsby-plugin-theme-ui'

// ___________________________________________________________________

type Props = {
  fill?: string
}

const CubeSpark: React.FC<Props> = ({ fill }) => (
  <SVG
    width="550"
    viewBox="0 0 560.47 588.02"
    initial="hidden"
    animate="visible"
    fill={fill}
  >
    <path
      className="cubespark-1"
      d="M1.5,452.87a1.53,1.53,0,0,0-1.06.43,1.51,1.51,0,0,0,0,2.13,1.5,1.5,0,0,0,2.12,0,1.51,1.51,0,0,0,0-2.13A1.53,1.53,0,0,0,1.5,452.87Z"
    />
    <path
      className="cubespark-1"
      d="M161.24,532.31a1.52,1.52,0,0,0-2,.68,1.5,1.5,0,1,0,2-.68Z"
    />
    <path
      className="cubespark-1"
      d="M55.19,479.45a1.5,1.5,0,1,0,.67,2A1.49,1.49,0,0,0,55.19,479.45Z"
    />
    <path
      className="cubespark-1"
      d="M214.26,558.75a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,214.26,558.75Z"
    />
    <path
      className="cubespark-1"
      d="M108.21,505.88a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,108.21,505.88Z"
    />
    <path
      className="cubespark-1"
      d="M266.61,585a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="266.61" cy="468.91" r="1.5" />
    <circle className="cubespark-1" cx="266.61" cy="351.3" r="1.5" />
    <circle className="cubespark-1" cx="266.61" cy="527.71" r="1.5" />
    <circle className="cubespark-1" cx="266.61" cy="410.11" r="1.5" />
    <path
      className="cubespark-1"
      d="M266.61,291a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M212.92,267.41a1.5,1.5,0,1,0-.67-2A1.5,1.5,0,0,0,212.92,267.41Z"
    />
    <path
      className="cubespark-1"
      d="M106.88,214.55a1.5,1.5,0,1,0-.68-2A1.5,1.5,0,0,0,106.88,214.55Z"
    />
    <path
      className="cubespark-1"
      d="M159.9,241a1.5,1.5,0,1,0-.68-2A1.5,1.5,0,0,0,159.9,241Z"
    />
    <path
      className="cubespark-1"
      d="M53.85,188.12a1.5,1.5,0,1,0-.67-2A1.49,1.49,0,0,0,53.85,188.12Z"
    />
    <path
      className="cubespark-1"
      d="M1.5,158.84a1.5,1.5,0,0,0-1.06.44A1.52,1.52,0,0,0,0,160.34a1.5,1.5,0,0,0,3,0,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,1.5,158.84Z"
    />
    <circle className="cubespark-1" cx="1.5" cy="336.76" r="1.5" />
    <circle className="cubespark-1" cx="1.5" cy="395.56" r="1.5" />
    <circle className="cubespark-1" cx="1.5" cy="277.95" r="1.5" />
    <circle className="cubespark-1" cx="1.5" cy="219.15" r="1.5" />
    <path
      className="cubespark-1"
      d="M280.21,3a1.52,1.52,0,0,0,1.07-.44,1.55,1.55,0,0,0,.43-1.06,1.53,1.53,0,0,0-.43-1.06,1.51,1.51,0,0,0-2.13,0,1.54,1.54,0,0,0-.44,1.06,1.56,1.56,0,0,0,.44,1.06A1.52,1.52,0,0,0,280.21,3Z"
    />
    <path
      className="cubespark-1"
      d="M70.18,108.56a1.5,1.5,0,1,0-2-.67A1.51,1.51,0,0,0,70.18,108.56Z"
    />
    <path
      className="cubespark-1"
      d="M228.21,29.27a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,228.21,29.27Z"
    />
    <path
      className="cubespark-1"
      d="M175.53,55.7a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,175.53,55.7Z"
    />
    <path
      className="cubespark-1"
      d="M122.85,82.13a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,122.85,82.13Z"
    />
    <path
      className="cubespark-1"
      d="M16.83,132.15a1.48,1.48,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,.44,1.06,1.48,1.48,0,0,0,1.06.44,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M176.56,211.6a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,176.56,211.6Z"
    />
    <path
      className="cubespark-1"
      d="M70.52,158.74a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,70.52,158.74Z"
    />
    <path
      className="cubespark-1"
      d="M229.58,238a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,229.58,238Z"
    />
    <path
      className="cubespark-1"
      d="M123.54,185.17a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,123.54,185.17Z"
    />
    <path
      className="cubespark-1"
      d="M281.94,264.31a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,281.94,264.31Z"
    />
    <path
      className="cubespark-1"
      d="M493.32,161.42a1.5,1.5,0,1,0-2-.66A1.5,1.5,0,0,0,493.32,161.42Z"
    />
    <path
      className="cubespark-1"
      d="M333.94,238a1.5,1.5,0,1,0,2,.66A1.5,1.5,0,0,0,333.94,238Z"
    />
    <path
      className="cubespark-1"
      d="M441.31,185.84a1.5,1.5,0,1,0-.67,2A1.5,1.5,0,0,0,441.31,185.84Z"
    />
    <path
      className="cubespark-1"
      d="M386.62,211.6a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,386.62,211.6Z"
    />
    <path
      className="cubespark-1"
      d="M546.39,132.59a1.52,1.52,0,0,0-1.06-.44,1.48,1.48,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,.44,1.06,1.48,1.48,0,0,0,1.06.44,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06A1.52,1.52,0,0,0,546.39,132.59Z"
    />
    <path
      className="cubespark-1"
      d="M491.63,108.56a1.5,1.5,0,1,0-.67-2A1.51,1.51,0,0,0,491.63,108.56Z"
    />
    <path
      className="cubespark-1"
      d="M385.59,55.7a1.5,1.5,0,1,0-.67-2A1.5,1.5,0,0,0,385.59,55.7Z"
    />
    <path
      className="cubespark-1"
      d="M438.61,82.13a1.5,1.5,0,1,0-.67-2A1.5,1.5,0,0,0,438.61,82.13Z"
    />
    <path
      className="cubespark-1"
      d="M332.57,29.27a1.5,1.5,0,1,0-.67-2A1.5,1.5,0,0,0,332.57,29.27Z"
    />
    <path
      className="cubespark-1"
      d="M295.58,585a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,295.58,585Z"
    />
    <path
      className="cubespark-1"
      d="M452.94,505.89a1.5,1.5,0,1,0,2,.66A1.5,1.5,0,0,0,452.94,505.89Z"
    />
    <path
      className="cubespark-1"
      d="M347.58,558.75a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,347.58,558.75Z"
    />
    <path
      className="cubespark-1"
      d="M505.62,479.46a1.5,1.5,0,1,0,2,.66A1.5,1.5,0,0,0,505.62,479.46Z"
    />
    <path
      className="cubespark-1"
      d="M400.26,532.32a1.5,1.5,0,1,0,2,.66A1.5,1.5,0,0,0,400.26,532.32Z"
    />
    <path
      className="cubespark-1"
      d="M559,452.87a1.49,1.49,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44A1.51,1.51,0,0,0,560,453.3,1.55,1.55,0,0,0,559,452.87Z"
    />
    <circle className="cubespark-1" cx="558.97" cy="219.15" r="1.5" />
    <circle className="cubespark-1" cx="558.97" cy="336.76" r="1.5" />
    <circle className="cubespark-1" cx="558.97" cy="395.56" r="1.5" />
    <circle className="cubespark-1" cx="558.97" cy="277.95" r="1.5" />
    <path
      className="cubespark-1"
      d="M559,161.84a1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5Z"
    />
    <path
      className="cubespark-1"
      d="M505.62,185.43a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,505.62,185.43Z"
    />
    <path
      className="cubespark-1"
      d="M454.29,214.55a1.51,1.51,0,0,0,.66-2,1.5,1.5,0,0,0-2.68,1.35A1.51,1.51,0,0,0,454.29,214.55Z"
    />
    <path
      className="cubespark-1"
      d="M401.61,241a1.5,1.5,0,1,0-2-.67A1.51,1.51,0,0,0,401.61,241Z"
    />
    <path
      className="cubespark-1"
      d="M348.93,267.41a1.5,1.5,0,1,0-1.35-2.68,1.5,1.5,0,0,0,1.35,2.68Z"
    />
    <path
      className="cubespark-1"
      d="M295.58,291a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,295.58,291Z"
    />
    <circle className="cubespark-1" cx="295.58" cy="351.3" r="1.5" />
    <circle className="cubespark-1" cx="295.58" cy="468.91" r="1.5" />
    <circle className="cubespark-1" cx="295.58" cy="527.71" r="1.5" />
    <circle className="cubespark-1" cx="295.58" cy="410.11" r="1.5" />
    <path
      className="cubespark-1"
      d="M12.23,446.32a1.52,1.52,0,0,0-1.07.44,1.49,1.49,0,0,0-.43,1.06,1.51,1.51,0,0,0,.43,1.06,1.51,1.51,0,0,0,2.13,0,1.56,1.56,0,0,0,.44-1.06,1.54,1.54,0,0,0-.44-1.06A1.52,1.52,0,0,0,12.23,446.32Z"
    />
    <path
      className="cubespark-1"
      d="M110.36,495.06a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,110.36,495.06Z"
    />
    <path
      className="cubespark-1"
      d="M159.76,521.36a1.5,1.5,0,1,0-2,.68A1.5,1.5,0,0,0,159.76,521.36Z"
    />
    <path
      className="cubespark-1"
      d="M61.63,470.77a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,61.63,470.77Z"
    />
    <path
      className="cubespark-1"
      d="M207.82,543.64a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,207.82,543.64Z"
    />
    <path
      className="cubespark-1"
      d="M255.89,567.78a1.48,1.48,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.52,1.52,0,0,0,.44,1.06,1.48,1.48,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,255.89,567.78Z"
    />
    <circle className="cubespark-1" cx="255.89" cy="461.19" r="1.5" />
    <circle className="cubespark-1" cx="255.89" cy="353.09" r="1.5" />
    <circle className="cubespark-1" cx="255.89" cy="407.14" r="1.5" />
    <circle className="cubespark-1" cx="255.89" cy="515.23" r="1.5" />
    <path
      className="cubespark-1"
      d="M255.89,297.55a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.48,1.48,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5A1.48,1.48,0,0,0,257,298,1.52,1.52,0,0,0,255.89,297.55Z"
    />
    <path
      className="cubespark-1"
      d="M60.29,203.22a1.5,1.5,0,1,0-.68-2A1.5,1.5,0,0,0,60.29,203.22Z"
    />
    <path
      className="cubespark-1"
      d="M108.35,225.5a1.5,1.5,0,1,0,2-.67A1.5,1.5,0,0,0,108.35,225.5Z"
    />
    <path
      className="cubespark-1"
      d="M157.75,251.8a1.5,1.5,0,1,0-.67-2A1.5,1.5,0,0,0,157.75,251.8Z"
    />
    <path
      className="cubespark-1"
      d="M206.48,276.1a1.5,1.5,0,1,0-.67-2A1.51,1.51,0,0,0,206.48,276.1Z"
    />
    <path
      className="cubespark-1"
      d="M12.23,176.08a1.57,1.57,0,0,0-1.07.44,1.53,1.53,0,0,0,0,2.13,1.51,1.51,0,1,0,1.07-2.57Z"
    />
    <circle className="cubespark-1" cx="12.23" cy="231.63" r="1.5" />
    <circle className="cubespark-1" cx="12.23" cy="339.72" r="1.5" />
    <circle className="cubespark-1" cx="12.23" cy="285.68" r="1.5" />
    <circle className="cubespark-1" cx="12.23" cy="393.77" r="1.5" />
    <path
      className="cubespark-1"
      d="M280.28,13.69a1.49,1.49,0,0,0,1.5-1.5,1.55,1.55,0,0,0-.43-1.06,1.51,1.51,0,0,0-2.57,1.06,1.5,1.5,0,0,0,.44,1.06A1.52,1.52,0,0,0,280.28,13.69Z"
    />
    <path
      className="cubespark-1"
      d="M135.71,86.41a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,135.71,86.41Z"
    />
    <path
      className="cubespark-1"
      d="M232.54,37.83a1.5,1.5,0,1,0-2-.67A1.51,1.51,0,0,0,232.54,37.83Z"
    />
    <path
      className="cubespark-1"
      d="M184.13,62.12a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,184.13,62.12Z"
    />
    <path
      className="cubespark-1"
      d="M87.29,110.7a1.5,1.5,0,1,0-2-.67A1.49,1.49,0,0,0,87.29,110.7Z"
    />
    <path
      className="cubespark-1"
      d="M38.21,132.15a1.48,1.48,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,.44,1.06,1.48,1.48,0,0,0,1.06.44,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M88.28,158.61a1.5,1.5,0,1,0-2,.68A1.5,1.5,0,0,0,88.28,158.61Z"
    />
    <path
      className="cubespark-1"
      d="M185.07,205.19a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,185.07,205.19Z"
    />
    <path
      className="cubespark-1"
      d="M136.34,180.89a1.5,1.5,0,1,0,.67,2A1.51,1.51,0,0,0,136.34,180.89Z"
    />
    <path
      className="cubespark-1"
      d="M234.48,231.49a1.5,1.5,0,1,0-2,.67A1.49,1.49,0,0,0,234.48,231.49Z"
    />
    <path
      className="cubespark-1"
      d="M281.87,256.61a1.52,1.52,0,0,0,1.06-.44,1.48,1.48,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.48,1.48,0,0,0,.44,1.06A1.5,1.5,0,0,0,281.87,256.61Z"
    />
    <path
      className="cubespark-1"
      d="M329.61,229.48a1.5,1.5,0,1,0,1.35,2.68,1.5,1.5,0,0,0-1.35-2.68Z"
    />
    <path
      className="cubespark-1"
      d="M476.87,157.27a1.5,1.5,0,1,0-.67,2A1.5,1.5,0,0,0,476.87,157.27Z"
    />
    <path
      className="cubespark-1"
      d="M427.79,183.58a1.5,1.5,0,1,0-2-.67A1.51,1.51,0,0,0,427.79,183.58Z"
    />
    <path
      className="cubespark-1"
      d="M380,205.86a1.5,1.5,0,1,0-.67,2A1.5,1.5,0,0,0,380,205.86Z"
    />
    <path
      className="cubespark-1"
      d="M524,132.15a1.48,1.48,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,.44,1.06,1.48,1.48,0,0,0,1.06.44,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M328.35,37.83a1.5,1.5,0,1,0-.68-2A1.52,1.52,0,0,0,328.35,37.83Z"
    />
    <path
      className="cubespark-1"
      d="M427.82,85.74a1.5,1.5,0,1,0-2,.67A1.5,1.5,0,0,0,427.82,85.74Z"
    />
    <path
      className="cubespark-1"
      d="M377.08,62.12a1.5,1.5,0,1,0-.67-2A1.5,1.5,0,0,0,377.08,62.12Z"
    />
    <path
      className="cubespark-1"
      d="M474.54,110.7a1.5,1.5,0,1,0-.67-2A1.51,1.51,0,0,0,474.54,110.7Z"
    />
    <path
      className="cubespark-1"
      d="M306.23,567.78a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.48,1.48,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-.44-1.06A1.48,1.48,0,0,0,306.23,567.78Z"
    />
    <path
      className="cubespark-1"
      d="M452.82,495.73a1.5,1.5,0,1,0-.67,2A1.5,1.5,0,0,0,452.82,495.73Z"
    />
    <path
      className="cubespark-1"
      d="M402.39,519.35a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,402.39,519.35Z"
    />
    <path
      className="cubespark-1"
      d="M499.22,470.77a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,499.22,470.77Z"
    />
    <path
      className="cubespark-1"
      d="M354,543.65a1.5,1.5,0,1,0,2,.66A1.5,1.5,0,0,0,354,543.65Z"
    />
    <path
      className="cubespark-1"
      d="M548.31,446.32a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="548.31" cy="285.68" r="1.5" />
    <circle className="cubespark-1" cx="548.31" cy="231.63" r="1.5" />
    <circle className="cubespark-1" cx="548.31" cy="339.72" r="1.5" />
    <circle className="cubespark-1" cx="548.31" cy="393.77" r="1.5" />
    <path
      className="cubespark-1"
      d="M548.31,176.08a1.56,1.56,0,0,0-1.06.44,1.51,1.51,0,0,0,0,2.13,1.55,1.55,0,0,0,1.06.43,1.49,1.49,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06A1.54,1.54,0,0,0,548.31,176.08Z"
    />
    <path
      className="cubespark-1"
      d="M452.15,227.51a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,452.15,227.51Z"
    />
    <path
      className="cubespark-1"
      d="M354,273.41a1.51,1.51,0,0,0-.67,2,1.5,1.5,0,1,0,.67-2Z"
    />
    <path
      className="cubespark-1"
      d="M499.22,200.54a1.5,1.5,0,0,0,1.35,2.68,1.51,1.51,0,0,0,.67-2A1.49,1.49,0,0,0,499.22,200.54Z"
    />
    <path
      className="cubespark-1"
      d="M401.73,251.13a1.5,1.5,0,0,0,2.68-1.34,1.51,1.51,0,0,0-2-.67A1.49,1.49,0,0,0,401.73,251.13Z"
    />
    <path
      className="cubespark-1"
      d="M306.23,297.55a1.52,1.52,0,0,0-1.06.44,1.48,1.48,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.48,1.48,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="306.23" cy="353.09" r="1.5" />
    <circle className="cubespark-1" cx="306.23" cy="461.19" r="1.5" />
    <circle className="cubespark-1" cx="306.23" cy="515.23" r="1.5" />
    <circle className="cubespark-1" cx="306.23" cy="407.14" r="1.5" />
    <path
      className="cubespark-1"
      d="M23,439.77a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M201.39,528.54a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,201.39,528.54Z"
    />
    <path
      className="cubespark-1"
      d="M157.62,508.4a1.5,1.5,0,1,0-2,.67A1.5,1.5,0,0,0,157.62,508.4Z"
    />
    <path
      className="cubespark-1"
      d="M68.06,462.08a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,68.06,462.08Z"
    />
    <path
      className="cubespark-1"
      d="M112.5,484.23a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,112.5,484.23Z"
    />
    <path
      className="cubespark-1"
      d="M245.16,550.54a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="245.16" cy="502.75" r="1.5" />
    <circle className="cubespark-1" cx="245.16" cy="404.17" r="1.5" />
    <circle className="cubespark-1" cx="245.16" cy="453.46" r="1.5" />
    <circle className="cubespark-1" cx="245.16" cy="354.88" r="1.5" />
    <path
      className="cubespark-1"
      d="M245.16,304.09a1.51,1.51,0,0,0-1.06,2.57,1.55,1.55,0,0,0,1.06.43,1.49,1.49,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,245.16,304.09Z"
    />
    <path
      className="cubespark-1"
      d="M112.5,237.79a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,112.5,237.79Z"
    />
    <path
      className="cubespark-1"
      d="M155.61,262.63a1.5,1.5,0,1,0-.68-2A1.5,1.5,0,0,0,155.61,262.63Z"
    />
    <path
      className="cubespark-1"
      d="M68.06,215.64a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,68.06,215.64Z"
    />
    <path
      className="cubespark-1"
      d="M200.05,284.78a1.5,1.5,0,1,0-.67-2A1.5,1.5,0,0,0,200.05,284.78Z"
    />
    <path
      className="cubespark-1"
      d="M23,193.33a1.52,1.52,0,0,0-1.06.44,1.48,1.48,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.48,1.48,0,0,0-.44-1.06A1.5,1.5,0,0,0,23,193.33Z"
    />
    <circle className="cubespark-1" cx="22.95" cy="293.4" r="1.5" />
    <circle className="cubespark-1" cx="22.95" cy="342.69" r="1.5" />
    <circle className="cubespark-1" cx="22.95" cy="391.98" r="1.5" />
    <circle className="cubespark-1" cx="22.95" cy="244.11" r="1.5" />
    <path
      className="cubespark-1"
      d="M280.35,24.38a1.51,1.51,0,0,0,1.07-2.56A1.51,1.51,0,0,0,279.29,24,1.55,1.55,0,0,0,280.35,24.38Z"
    />
    <path
      className="cubespark-1"
      d="M148.57,90.69a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,148.57,90.69Z"
    />
    <path
      className="cubespark-1"
      d="M236.87,46.38a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,236.87,46.38Z"
    />
    <path
      className="cubespark-1"
      d="M192.72,68.53a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,192.72,68.53Z"
    />
    <path
      className="cubespark-1"
      d="M104.41,112.84a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,104.41,112.84Z"
    />
    <path
      className="cubespark-1"
      d="M60.65,134.71a1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5A1.52,1.52,0,0,0,60.65,134.71Z"
    />
    <path
      className="cubespark-1"
      d="M238,220.93a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,238,220.93Z"
    />
    <path
      className="cubespark-1"
      d="M193.58,198.77a1.5,1.5,0,0,0-1.34,2.69,1.5,1.5,0,1,0,1.34-2.69Z"
    />
    <path
      className="cubespark-1"
      d="M104.7,154.46a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,104.7,154.46Z"
    />
    <path
      className="cubespark-1"
      d="M147.8,179.3a1.5,1.5,0,1,0-.67-2A1.5,1.5,0,0,0,147.8,179.3Z"
    />
    <path
      className="cubespark-1"
      d="M281.8,245.92a1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5Z"
    />
    <path
      className="cubespark-1"
      d="M326.62,223.61a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,326.62,223.61Z"
    />
    <path
      className="cubespark-1"
      d="M370.78,201.46a1.5,1.5,0,1,0-2-.67A1.51,1.51,0,0,0,370.78,201.46Z"
    />
    <path
      className="cubespark-1"
      d="M457.74,154.47a1.5,1.5,0,1,0,2,.66A1.5,1.5,0,0,0,457.74,154.47Z"
    />
    <path
      className="cubespark-1"
      d="M413.59,176.62a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,413.59,176.62Z"
    />
    <path
      className="cubespark-1"
      d="M502.57,132.15a1.48,1.48,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,.44,1.06,1.48,1.48,0,0,0,1.06.44,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M457.45,112.84a1.5,1.5,0,1,0-.67-2A1.51,1.51,0,0,0,457.45,112.84Z"
    />
    <path
      className="cubespark-1"
      d="M370.58,67.86a1.5,1.5,0,1,0-2,.67A1.5,1.5,0,0,0,370.58,67.86Z"
    />
    <path
      className="cubespark-1"
      d="M413,90.69a1.5,1.5,0,1,0-.67-2A1.49,1.49,0,0,0,413,90.69Z"
    />
    <path
      className="cubespark-1"
      d="M326.14,45.71a1.5,1.5,0,1,0-2,.67A1.5,1.5,0,0,0,326.14,45.71Z"
    />
    <path
      className="cubespark-1"
      d="M316.89,550.54a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0,318,551,1.52,1.52,0,0,0,316.89,550.54Z"
    />
    <path
      className="cubespark-1"
      d="M404.52,506.39a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,404.52,506.39Z"
    />
    <path
      className="cubespark-1"
      d="M492.83,462.08a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,492.83,462.08Z"
    />
    <path
      className="cubespark-1"
      d="M360.37,528.54a1.5,1.5,0,1,0,1.35,2.68,1.5,1.5,0,0,0-1.35-2.68Z"
    />
    <path
      className="cubespark-1"
      d="M450.69,484.9a1.5,1.5,0,1,0-.67,2A1.51,1.51,0,0,0,450.69,484.9Z"
    />
    <path
      className="cubespark-1"
      d="M537.66,439.77a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,537.66,439.77Z"
    />
    <circle className="cubespark-1" cx="537.66" cy="244.11" r="1.5" />
    <circle className="cubespark-1" cx="537.66" cy="342.69" r="1.5" />
    <circle className="cubespark-1" cx="537.66" cy="293.4" r="1.5" />
    <circle className="cubespark-1" cx="537.66" cy="391.98" r="1.5" />
    <path
      className="cubespark-1"
      d="M537.66,193.33a1.5,1.5,0,0,0-1.06.44,1.48,1.48,0,0,0-.44,1.06,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.48,1.48,0,0,0-.44-1.06A1.52,1.52,0,0,0,537.66,193.33Z"
    />
    <path
      className="cubespark-1"
      d="M450,240.47a1.5,1.5,0,1,0-2-.66A1.5,1.5,0,0,0,450,240.47Z"
    />
    <path
      className="cubespark-1"
      d="M360.37,282.1a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,360.37,282.1Z"
    />
    <path
      className="cubespark-1"
      d="M492.83,215.64a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,492.83,215.64Z"
    />
    <path
      className="cubespark-1"
      d="M404.52,260a1.5,1.5,0,0,0,1.35,2.68,1.5,1.5,0,1,0-1.35-2.68Z"
    />
    <path
      className="cubespark-1"
      d="M316.89,304.09a1.5,1.5,0,0,0-1.06.44,1.51,1.51,0,0,0,0,2.13,1.53,1.53,0,0,0,1.06.43,1.55,1.55,0,0,0,1.06-.43,1.51,1.51,0,0,0-1.06-2.57Z"
    />
    <circle className="cubespark-1" cx="316.89" cy="404.17" r="1.5" />
    <circle className="cubespark-1" cx="316.89" cy="354.88" r="1.5" />
    <circle className="cubespark-1" cx="316.89" cy="453.46" r="1.5" />
    <circle className="cubespark-1" cx="316.89" cy="502.75" r="1.5" />
    <path
      className="cubespark-1"
      d="M33.67,433.22a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.51,1.51,0,0,0,2.13,0,1.53,1.53,0,0,0,.43-1.06,1.55,1.55,0,0,0-.43-1.06A1.52,1.52,0,0,0,33.67,433.22Z"
    />
    <path
      className="cubespark-1"
      d="M184.92,508.43a1.52,1.52,0,0,0-2,.68,1.5,1.5,0,1,0,2-.68Z"
    />
    <path
      className="cubespark-1"
      d="M84.53,458.4a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,84.53,458.4Z"
    />
    <path
      className="cubespark-1"
      d="M134.72,483.42a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,134.72,483.42Z"
    />
    <path
      className="cubespark-1"
      d="M234.44,533.3a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,234.44,533.3Z"
    />
    <circle className="cubespark-1" cx="234.44" cy="423.47" r="1.5" />
    <circle className="cubespark-1" cx="234.44" cy="367.81" r="1.5" />
    <circle className="cubespark-1" cx="234.44" cy="479.13" r="1.5" />
    <path
      className="cubespark-1"
      d="M234.44,310.64a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M183.58,288.46a1.5,1.5,0,1,0-.68-2A1.5,1.5,0,0,0,183.58,288.46Z"
    />
    <path
      className="cubespark-1"
      d="M83.2,238.43a1.5,1.5,0,1,0-.68-2A1.49,1.49,0,0,0,83.2,238.43Z"
    />
    <path
      className="cubespark-1"
      d="M133.39,263.45a1.5,1.5,0,1,0-.68-2A1.5,1.5,0,0,0,133.39,263.45Z"
    />
    <path
      className="cubespark-1"
      d="M33.67,210.57a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.51,1.51,0,0,0,2.57,1.06,1.55,1.55,0,0,0,.43-1.06,1.49,1.49,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="33.67" cy="267.73" r="1.5" />
    <circle className="cubespark-1" cx="33.67" cy="379.06" r="1.5" />
    <circle className="cubespark-1" cx="33.67" cy="323.39" r="1.5" />
    <path
      className="cubespark-1"
      d="M280.42,35.08a1.48,1.48,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-.44-1.06,1.48,1.48,0,0,0-1.06-.44,1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5Z"
    />
    <path
      className="cubespark-1"
      d="M131.5,110a1.5,1.5,0,1,0-2-.66A1.5,1.5,0,0,0,131.5,110Z"
    />
    <path
      className="cubespark-1"
      d="M181.37,85a1.5,1.5,0,1,0-2-.67A1.51,1.51,0,0,0,181.37,85Z"
    />
    <path
      className="cubespark-1"
      d="M231.23,59.94a1.5,1.5,0,1,0-2-.67A1.51,1.51,0,0,0,231.23,59.94Z"
    />
    <path
      className="cubespark-1"
      d="M82,132.59a1.52,1.52,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06A1.52,1.52,0,0,0,82,132.59Z"
    />
    <path
      className="cubespark-1"
      d="M232.88,209.38a1.5,1.5,0,1,0-2,.67A1.5,1.5,0,0,0,232.88,209.38Z"
    />
    <path
      className="cubespark-1"
      d="M131.83,157.33a1.5,1.5,0,1,0,.67,2A1.51,1.51,0,0,0,131.83,157.33Z"
    />
    <path
      className="cubespark-1"
      d="M182,182.35a1.5,1.5,0,1,0,.67,2A1.51,1.51,0,0,0,182,182.35Z"
    />
    <path
      className="cubespark-1"
      d="M281.73,235.23a1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06,1.52,1.52,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06A1.5,1.5,0,0,0,281.73,235.23Z"
    />
    <path
      className="cubespark-1"
      d="M430.65,157.33a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,430.65,157.33Z"
    />
    <path
      className="cubespark-1"
      d="M380.78,182.35a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,380.78,182.35Z"
    />
    <path
      className="cubespark-1"
      d="M330.92,207.37a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,330.92,207.37Z"
    />
    <path
      className="cubespark-1"
      d="M481.19,132.15a1.52,1.52,0,0,0-1.07.44,1.55,1.55,0,0,0-.43,1.06,1.49,1.49,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M329.27,57.93A1.5,1.5,0,0,0,332,59.27a1.5,1.5,0,1,0-2.69-1.34Z"
    />
    <path
      className="cubespark-1"
      d="M430.33,110a1.5,1.5,0,1,0-.68-2A1.5,1.5,0,0,0,430.33,110Z"
    />
    <path
      className="cubespark-1"
      d="M380.14,85a1.5,1.5,0,1,0-.68-2A1.49,1.49,0,0,0,380.14,85Z"
    />
    <path
      className="cubespark-1"
      d="M327.54,533.3a1.52,1.52,0,0,0-1.06.44,1.54,1.54,0,0,0-.44,1.06,1.56,1.56,0,0,0,.44,1.06,1.51,1.51,0,0,0,2.13,0,1.55,1.55,0,0,0,.43-1.06,1.49,1.49,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M476.47,458.4a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,476.47,458.4Z"
    />
    <path
      className="cubespark-1"
      d="M426.6,483.42a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,426.6,483.42Z"
    />
    <path
      className="cubespark-1"
      d="M376.74,508.44a1.5,1.5,0,1,0,2,.66A1.5,1.5,0,0,0,376.74,508.44Z"
    />
    <path
      className="cubespark-1"
      d="M527,433.22a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,527,433.22Z"
    />
    <circle className="cubespark-1" cx="527" cy="267.73" r="1.5" />
    <circle className="cubespark-1" cx="527" cy="323.39" r="1.5" />
    <circle className="cubespark-1" cx="527" cy="379.06" r="1.5" />
    <path
      className="cubespark-1"
      d="M527,210.57a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M476.47,235.75a1.5,1.5,0,1,0,2,.66A1.5,1.5,0,0,0,476.47,235.75Z"
    />
    <path
      className="cubespark-1"
      d="M426.6,260.76a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,426.6,260.76Z"
    />
    <path
      className="cubespark-1"
      d="M378.08,288.46a1.5,1.5,0,1,0-2-.66A1.5,1.5,0,0,0,378.08,288.46Z"
    />
    <path
      className="cubespark-1"
      d="M327.54,310.64a1.52,1.52,0,0,0-1.06.44,1.56,1.56,0,0,0-.44,1.06,1.54,1.54,0,0,0,.44,1.06,1.51,1.51,0,0,0,2.13,0,1.53,1.53,0,0,0,.43-1.06,1.55,1.55,0,0,0-.43-1.06A1.52,1.52,0,0,0,327.54,310.64Z"
    />
    <circle className="cubespark-1" cx="327.54" cy="423.47" r="1.5" />
    <circle className="cubespark-1" cx="327.54" cy="479.13" r="1.5" />
    <circle className="cubespark-1" cx="327.54" cy="367.81" r="1.5" />
    <path
      className="cubespark-1"
      d="M44.4,426.67a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M134.73,471.52a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,134.73,471.52Z"
    />
    <path
      className="cubespark-1"
      d="M89.9,449.18a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,89.9,449.18Z"
    />
    <path
      className="cubespark-1"
      d="M179.55,493.87a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,179.55,493.87Z"
    />
    <path
      className="cubespark-1"
      d="M223.71,516.06a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="223.71" cy="368.41" r="1.5" />
    <circle className="cubespark-1" cx="223.71" cy="418.12" r="1.5" />
    <circle className="cubespark-1" cx="223.71" cy="467.84" r="1.5" />
    <path
      className="cubespark-1"
      d="M223.71,317.19a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,223.71,317.19Z"
    />
    <path
      className="cubespark-1"
      d="M178.21,297.69a1.5,1.5,0,1,0,1.34-2.69,1.5,1.5,0,0,0-1.34,2.69Z"
    />
    <path
      className="cubespark-1"
      d="M134.72,272.66a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,134.72,272.66Z"
    />
    <path
      className="cubespark-1"
      d="M87.89,251a1.5,1.5,0,1,0,2-.67A1.51,1.51,0,0,0,87.89,251Z"
    />
    <path
      className="cubespark-1"
      d="M44.4,227.81a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,44.4,227.81Z"
    />
    <circle className="cubespark-1" cx="44.4" cy="328.74" r="1.5" />
    <circle className="cubespark-1" cx="44.4" cy="279.02" r="1.5" />
    <circle className="cubespark-1" cx="44.4" cy="378.46" r="1.5" />
    <path
      className="cubespark-1"
      d="M280.49,45.77a1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5Z"
    />
    <path
      className="cubespark-1"
      d="M147.56,112.65a1.5,1.5,0,1,0-2-.67A1.51,1.51,0,0,0,147.56,112.65Z"
    />
    <path
      className="cubespark-1"
      d="M236.63,68a1.5,1.5,0,1,0-2-.67A1.51,1.51,0,0,0,236.63,68Z"
    />
    <path
      className="cubespark-1"
      d="M192.09,90.3a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,192.09,90.3Z"
    />
    <path
      className="cubespark-1"
      d="M103.85,133.65a1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44A1.5,1.5,0,0,0,103.85,133.65Z"
    />
    <path
      className="cubespark-1"
      d="M192.67,177a1.5,1.5,0,1,0,.67,2A1.49,1.49,0,0,0,192.67,177Z"
    />
    <path
      className="cubespark-1"
      d="M148.52,156.67a1.5,1.5,0,1,0-2,.67A1.49,1.49,0,0,0,148.52,156.67Z"
    />
    <path
      className="cubespark-1"
      d="M235.49,200a1.5,1.5,0,1,0,2-.67A1.5,1.5,0,0,0,235.49,200Z"
    />
    <path
      className="cubespark-1"
      d="M281.66,224.54a1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06,1.52,1.52,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06A1.5,1.5,0,0,0,281.66,224.54Z"
    />
    <path
      className="cubespark-1"
      d="M325.52,199.35a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,325.52,199.35Z"
    />
    <path
      className="cubespark-1"
      d="M370.06,177a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,370.06,177Z"
    />
    <path
      className="cubespark-1"
      d="M414.6,154.66a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,414.6,154.66Z"
    />
    <path
      className="cubespark-1"
      d="M459.81,132.15a1.52,1.52,0,0,0-1.07.44,1.51,1.51,0,0,0-.43,1.06,1.49,1.49,0,0,0,.43,1.06,1.51,1.51,0,0,0,2.13,0,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M368.81,88.29a1.5,1.5,0,1,0,2-.67A1.5,1.5,0,0,0,368.81,88.29Z"
    />
    <path
      className="cubespark-1"
      d="M326.66,67.28a1.5,1.5,0,1,0-2,.68A1.5,1.5,0,0,0,326.66,67.28Z"
    />
    <path
      className="cubespark-1"
      d="M413.63,110.64a1.5,1.5,0,1,0,2-.68A1.51,1.51,0,0,0,413.63,110.64Z"
    />
    <path
      className="cubespark-1"
      d="M338.2,516.06a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M471.14,449.18a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,471.14,449.18Z"
    />
    <path
      className="cubespark-1"
      d="M426.6,471.52A1.5,1.5,0,1,0,428,474.2a1.5,1.5,0,0,0-1.35-2.68Z"
    />
    <path
      className="cubespark-1"
      d="M382.06,493.87a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,382.06,493.87Z"
    />
    <path
      className="cubespark-1"
      d="M516.35,426.67a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="516.35" cy="328.74" r="1.5" />
    <circle className="cubespark-1" cx="516.35" cy="378.46" r="1.5" />
    <circle className="cubespark-1" cx="516.35" cy="279.02" r="1.5" />
    <path
      className="cubespark-1"
      d="M516.35,227.81a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,516.35,227.81Z"
    />
    <path
      className="cubespark-1"
      d="M472.48,253a1.5,1.5,0,1,0-2-.66A1.5,1.5,0,0,0,472.48,253Z"
    />
    <path
      className="cubespark-1"
      d="M426.6,272.66a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,426.6,272.66Z"
    />
    <path
      className="cubespark-1"
      d="M382.06,295a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,382.06,295Z"
    />
    <path
      className="cubespark-1"
      d="M338.2,317.19a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,338.2,317.19Z"
    />
    <circle className="cubespark-1" cx="338.2" cy="368.41" r="1.5" />
    <circle className="cubespark-1" cx="338.2" cy="418.12" r="1.5" />
    <circle className="cubespark-1" cx="338.2" cy="467.84" r="1.5" />
    <path
      className="cubespark-1"
      d="M55.12,420.12a1.52,1.52,0,0,0-1.06.44,1.56,1.56,0,0,0-.44,1.06,1.5,1.5,0,1,0,3,0,1.55,1.55,0,0,0-.43-1.06A1.52,1.52,0,0,0,55.12,420.12Z"
    />
    <path
      className="cubespark-1"
      d="M159.7,475.43a1.5,1.5,0,1,0-.68-2A1.49,1.49,0,0,0,159.7,475.43Z"
    />
    <path
      className="cubespark-1"
      d="M107.08,449.2a1.5,1.5,0,1,0-.68-2A1.5,1.5,0,0,0,107.08,449.2Z"
    />
    <path
      className="cubespark-1"
      d="M214.05,501.38a1.51,1.51,0,0,0,0-2.13,1.55,1.55,0,0,0-1.06-.43,1.49,1.49,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44A1.52,1.52,0,0,0,214.05,501.38Z"
    />
    <circle className="cubespark-1" cx="212.99" cy="383.6" r="1.5" />
    <circle className="cubespark-1" cx="212.99" cy="441.96" r="1.5" />
    <path
      className="cubespark-1"
      d="M213,326.74a1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06,1.52,1.52,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06A1.5,1.5,0,0,0,213,326.74Z"
    />
    <path
      className="cubespark-1"
      d="M161.71,299.68a1.5,1.5,0,1,0-2,.67A1.5,1.5,0,0,0,161.71,299.68Z"
    />
    <path
      className="cubespark-1"
      d="M108.41,271.44a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,108.41,271.44Z"
    />
    <path
      className="cubespark-1"
      d="M55.12,245.05a1.52,1.52,0,0,0-1.06.44,1.54,1.54,0,0,0-.44,1.06,1.56,1.56,0,0,0,.44,1.06,1.51,1.51,0,0,0,2.13,0,1.55,1.55,0,0,0,.43-1.06,1.49,1.49,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="55.13" cy="304.91" r="1.5" />
    <circle className="cubespark-1" cx="55.13" cy="363.26" r="1.5" />
    <path
      className="cubespark-1"
      d="M280.56,56.46a1.5,1.5,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06,1.5,1.5,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5A1.5,1.5,0,0,0,279.5,56,1.52,1.52,0,0,0,280.56,56.46Z"
    />
    <path
      className="cubespark-1"
      d="M175.33,106.08a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,175.33,106.08Z"
    />
    <path
      className="cubespark-1"
      d="M229,82.53a1.5,1.5,0,1,0-2-.66A1.5,1.5,0,0,0,229,82.53Z"
    />
    <path
      className="cubespark-1"
      d="M123.73,132.15a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M177,158.54a1.5,1.5,0,1,0,.67,2A1.51,1.51,0,0,0,177,158.54Z"
    />
    <path
      className="cubespark-1"
      d="M227.63,185.44a1.5,1.5,0,1,0,2-.67A1.51,1.51,0,0,0,227.63,185.44Z"
    />
    <path
      className="cubespark-1"
      d="M281.59,213.84a1.55,1.55,0,0,0,1.06-.43,1.51,1.51,0,0,0-1.06-2.57,1.5,1.5,0,0,0-1.06.44,1.51,1.51,0,0,0,0,2.13A1.53,1.53,0,0,0,281.59,213.84Z"
    />
    <path
      className="cubespark-1"
      d="M386.82,161.22a1.5,1.5,0,1,0-2-.66A1.5,1.5,0,0,0,386.82,161.22Z"
    />
    <path
      className="cubespark-1"
      d="M333.19,184.77a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,333.19,184.77Z"
    />
    <path
      className="cubespark-1"
      d="M439.93,133.65a1.5,1.5,0,1,0-3,0,1.49,1.49,0,0,0,.43,1.06,1.51,1.51,0,0,0,2.13,0A1.5,1.5,0,0,0,439.93,133.65Z"
    />
    <path
      className="cubespark-1"
      d="M385.14,108.76a1.5,1.5,0,1,0-.68-2A1.5,1.5,0,0,0,385.14,108.76Z"
    />
    <path
      className="cubespark-1"
      d="M334.53,81.86a1.5,1.5,0,1,0-2,.67A1.5,1.5,0,0,0,334.53,81.86Z"
    />
    <path
      className="cubespark-1"
      d="M348.86,498.82a1.51,1.51,0,0,0-1.07,2.56,1.51,1.51,0,0,0,2.13-2.13A1.55,1.55,0,0,0,348.86,498.82Z"
    />
    <path
      className="cubespark-1"
      d="M454.09,449.19a1.5,1.5,0,0,0-1.35-2.68,1.5,1.5,0,1,0,1.35,2.68Z"
    />
    <path
      className="cubespark-1"
      d="M399.79,474.76a1.5,1.5,0,1,0,.67-2A1.51,1.51,0,0,0,399.79,474.76Z"
    />
    <path
      className="cubespark-1"
      d="M506.75,422.68a1.48,1.48,0,0,0,.44-1.06,1.52,1.52,0,0,0-.44-1.06,1.5,1.5,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.48,1.48,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44A1.5,1.5,0,0,0,506.75,422.68Z"
    />
    <circle className="cubespark-1" cx="505.69" cy="304.91" r="1.5" />
    <circle className="cubespark-1" cx="505.69" cy="363.27" r="1.5" />
    <path
      className="cubespark-1"
      d="M505.69,248.05a1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5Z"
    />
    <path
      className="cubespark-1"
      d="M400.46,297.67a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,400.46,297.67Z"
    />
    <path
      className="cubespark-1"
      d="M454.75,272.11a1.5,1.5,0,1,0-.66,2A1.5,1.5,0,0,0,454.75,272.11Z"
    />
    <path
      className="cubespark-1"
      d="M348.86,323.74a1.49,1.49,0,0,0-1.5,1.5,1.55,1.55,0,0,0,.43,1.06,1.51,1.51,0,0,0,2.57-1.06,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,348.86,323.74Z"
    />
    <circle className="cubespark-1" cx="348.86" cy="441.96" r="1.5" />
    <circle className="cubespark-1" cx="348.86" cy="383.6" r="1.5" />
    <path
      className="cubespark-1"
      d="M67.35,415.08a1.48,1.48,0,0,0-.44-1.06,1.5,1.5,0,0,0-1.06-.44,1.52,1.52,0,0,0-1.06.44,1.48,1.48,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44A1.52,1.52,0,0,0,67.35,415.08Z"
    />
    <path
      className="cubespark-1"
      d="M156.12,461.75a1.5,1.5,0,1,0-.67-2A1.5,1.5,0,0,0,156.12,461.75Z"
    />
    <path
      className="cubespark-1"
      d="M110.65,439.08a1.5,1.5,0,1,0-.67-2A1.5,1.5,0,0,0,110.65,439.08Z"
    />
    <path
      className="cubespark-1"
      d="M202.26,481.57a1.5,1.5,0,0,0-1.5,1.5,1.48,1.48,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-.44,1.48,1.48,0,0,0,.44-1.06,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,202.26,481.57Z"
    />
    <circle className="cubespark-1" cx="202.26" cy="382.22" r="1.5" />
    <circle className="cubespark-1" cx="202.26" cy="432.65" r="1.5" />
    <path
      className="cubespark-1"
      d="M202.26,330.29a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M112,285.11a1.5,1.5,0,1,0,.67,2A1.49,1.49,0,0,0,112,285.11Z"
    />
    <path
      className="cubespark-1"
      d="M157.46,307.78a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,157.46,307.78Z"
    />
    <path
      className="cubespark-1"
      d="M65.85,265.29a1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5Z"
    />
    <circle className="cubespark-1" cx="65.85" cy="314.22" r="1.5" />
    <circle className="cubespark-1" cx="65.85" cy="364.65" r="1.5" />
    <path
      className="cubespark-1"
      d="M280.63,67.15a1.5,1.5,0,0,0,1.06-.44,1.48,1.48,0,0,0,.44-1.06,1.52,1.52,0,0,0-.44-1.06,1.5,1.5,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.48,1.48,0,0,0,.44,1.06A1.52,1.52,0,0,0,280.63,67.15Z"
    />
    <path
      className="cubespark-1"
      d="M191,112.33a1.51,1.51,0,0,0,.66-2,1.5,1.5,0,0,0-2.68,1.35A1.51,1.51,0,0,0,191,112.33Z"
    />
    <path
      className="cubespark-1"
      d="M236.13,89.66a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,236.13,89.66Z"
    />
    <path
      className="cubespark-1"
      d="M146.61,133.65a1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44A1.5,1.5,0,0,0,146.61,133.65Z"
    />
    <path
      className="cubespark-1"
      d="M191.92,157a1.5,1.5,0,1,0-2,.67A1.5,1.5,0,0,0,191.92,157Z"
    />
    <path
      className="cubespark-1"
      d="M235.38,180.33a1.5,1.5,0,1,0-.67-2A1.5,1.5,0,0,0,235.38,180.33Z"
    />
    <path
      className="cubespark-1"
      d="M281.52,203.15a1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5Z"
    />
    <path
      className="cubespark-1"
      d="M328,178.31a1.5,1.5,0,1,0-.67,2A1.5,1.5,0,0,0,328,178.31Z"
    />
    <path
      className="cubespark-1"
      d="M371.2,155a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,371.2,155Z"
    />
    <path
      className="cubespark-1"
      d="M417,132.15a1.52,1.52,0,0,0-1.06.44,1.56,1.56,0,0,0-.44,1.06,1.54,1.54,0,0,0,.44,1.06,1.51,1.51,0,0,0,2.13,0,1.53,1.53,0,0,0,.43-1.06,1.55,1.55,0,0,0-.43-1.06A1.52,1.52,0,0,0,417,132.15Z"
    />
    <path
      className="cubespark-1"
      d="M324.76,87.65a1.5,1.5,0,1,0,2-.67A1.5,1.5,0,0,0,324.76,87.65Z"
    />
    <path
      className="cubespark-1"
      d="M370.23,110.32a1.5,1.5,0,0,0,2.69,1.34,1.5,1.5,0,1,0-2.69-1.34Z"
    />
    <path
      className="cubespark-1"
      d="M361,483.07a1.52,1.52,0,0,0-.44-1.06,1.5,1.5,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.48,1.48,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-.44A1.48,1.48,0,0,0,361,483.07Z"
    />
    <path
      className="cubespark-1"
      d="M405.36,461.75a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,405.36,461.75Z"
    />
    <path
      className="cubespark-1"
      d="M448.52,438.41a1.5,1.5,0,1,0,.67-2A1.49,1.49,0,0,0,448.52,438.41Z"
    />
    <path
      className="cubespark-1"
      d="M495,413.58A1.5,1.5,0,0,0,494,414a1.48,1.48,0,0,0-.44,1.06,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.48,1.48,0,0,0-.44-1.06A1.52,1.52,0,0,0,495,413.58Z"
    />
    <circle className="cubespark-1" cx="495.04" cy="364.65" r="1.5" />
    <circle className="cubespark-1" cx="495.04" cy="314.22" r="1.5" />
    <path
      className="cubespark-1"
      d="M495,262.29a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,495,262.29Z"
    />
    <path
      className="cubespark-1"
      d="M404,307.78a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,404,307.78Z"
    />
    <path
      className="cubespark-1"
      d="M449.19,285.11a1.5,1.5,0,1,0,2,.67A1.51,1.51,0,0,0,449.19,285.11Z"
    />
    <path
      className="cubespark-1"
      d="M359.51,333.29a1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5Z"
    />
    <circle className="cubespark-1" cx="359.51" cy="382.22" r="1.5" />
    <circle className="cubespark-1" cx="359.51" cy="432.65" r="1.5" />
    <path
      className="cubespark-1"
      d="M76.57,407a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.51,1.51,0,0,0,2.57,1.06,1.55,1.55,0,0,0,.43-1.06,1.49,1.49,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M134.72,435.84a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,134.72,435.84Z"
    />
    <path
      className="cubespark-1"
      d="M191.54,464.33a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.48,1.48,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.52,1.52,0,0,0,1.06-.44,1.48,1.48,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="191.54" cy="402.08" r="1.5" />
    <path
      className="cubespark-1"
      d="M191.54,336.84a1.5,1.5,0,0,0-1.06.44,1.48,1.48,0,0,0-.44,1.06,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.48,1.48,0,0,0-.44-1.06A1.52,1.52,0,0,0,191.54,336.84Z"
    />
    <path
      className="cubespark-1"
      d="M134.72,308.34a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,134.72,308.34Z"
    />
    <path
      className="cubespark-1"
      d="M76.57,279.53a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.51,1.51,0,0,0,2.57,1.06,1.55,1.55,0,0,0,.43-1.06,1.49,1.49,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="76.57" cy="344.78" r="1.5" />
    <path
      className="cubespark-1"
      d="M280.7,77.85a1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5Z"
    />
    <path
      className="cubespark-1"
      d="M224.27,106.34a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,224.27,106.34Z"
    />
    <path
      className="cubespark-1"
      d="M166.49,132.15a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M224.64,161a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,224.64,161Z"
    />
    <path
      className="cubespark-1"
      d="M281.45,192.46A1.5,1.5,0,0,0,283,191a1.5,1.5,0,0,0-.44-1.06,1.52,1.52,0,0,0-1.06-.44A1.5,1.5,0,0,0,280,191a1.52,1.52,0,0,0,.44,1.06A1.5,1.5,0,0,0,281.45,192.46Z"
    />
    <path
      className="cubespark-1"
      d="M337.88,161a1.5,1.5,0,0,0,1.35,2.68,1.5,1.5,0,1,0-1.35-2.68Z"
    />
    <path
      className="cubespark-1"
      d="M395.66,132.15a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.51,1.51,0,0,0,2.13,0,1.53,1.53,0,0,0,.43-1.06,1.55,1.55,0,0,0-.43-1.06A1.52,1.52,0,0,0,395.66,132.15Z"
    />
    <path
      className="cubespark-1"
      d="M337.51,106.34a1.5,1.5,0,1,0-.67-2A1.51,1.51,0,0,0,337.51,106.34Z"
    />
    <path
      className="cubespark-1"
      d="M370.17,464.33a1.48,1.48,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.48,1.48,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M426.6,435.84a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,426.6,435.84Z"
    />
    <path
      className="cubespark-1"
      d="M484.38,407a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="484.38" cy="344.78" r="1.5" />
    <path
      className="cubespark-1"
      d="M484.38,279.53a1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M426.6,308.34A1.5,1.5,0,1,0,428,311a1.5,1.5,0,0,0-1.35-2.68Z"
    />
    <path
      className="cubespark-1"
      d="M370.17,336.84a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.48,1.48,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.48,1.48,0,0,0-.44-1.06A1.52,1.52,0,0,0,370.17,336.84Z"
    />
    <circle className="cubespark-1" cx="370.17" cy="402.08" r="1.5" />
    <path
      className="cubespark-1"
      d="M88.8,402a1.5,1.5,0,0,0-.44-1.06,1.52,1.52,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M134.72,423.94a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,134.72,423.94Z"
    />
    <path
      className="cubespark-1"
      d="M180.81,447.09a1.5,1.5,0,0,0-1.5,1.5,1.48,1.48,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-.44,1.48,1.48,0,0,0,.44-1.06,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,180.81,447.09Z"
    />
    <circle className="cubespark-1" cx="180.81" cy="396.74" r="1.5" />
    <path
      className="cubespark-1"
      d="M180.81,343.38a1.5,1.5,0,0,0-1.5,1.5,1.48,1.48,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-.44,1.48,1.48,0,0,0,.44-1.06,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,180.81,343.38Z"
    />
    <path
      className="cubespark-1"
      d="M134.72,320.23a1.5,1.5,0,1,0,.68,2A1.51,1.51,0,0,0,134.72,320.23Z"
    />
    <path
      className="cubespark-1"
      d="M88.36,297.21a1.52,1.52,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0,88.36,297.21Z"
    />
    <circle className="cubespark-1" cx="87.3" cy="350.13" r="1.5" />
    <path
      className="cubespark-1"
      d="M280.77,88.54a1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5Z"
    />
    <path
      className="cubespark-1"
      d="M233,111a1.5,1.5,0,1,0,.67-2A1.5,1.5,0,0,0,233,111Z"
    />
    <path
      className="cubespark-1"
      d="M188.93,132.59a1.52,1.52,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06A1.52,1.52,0,0,0,188.93,132.59Z"
    />
    <path
      className="cubespark-1"
      d="M236,157.63a1.5,1.5,0,1,0-2,.67A1.5,1.5,0,0,0,236,157.63Z"
    />
    <path
      className="cubespark-1"
      d="M281.38,181.77a1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06,1.52,1.52,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06A1.5,1.5,0,0,0,281.38,181.77Z"
    />
    <path
      className="cubespark-1"
      d="M329.17,156.29a1.5,1.5,0,1,0-.67,2A1.5,1.5,0,0,0,329.17,156.29Z"
    />
    <path
      className="cubespark-1"
      d="M374.28,132.15a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.48,1.48,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.52,1.52,0,0,0-.44-1.06A1.48,1.48,0,0,0,374.28,132.15Z"
    />
    <path
      className="cubespark-1"
      d="M326.86,111.69a1.5,1.5,0,1,0-.67-2A1.49,1.49,0,0,0,326.86,111.69Z"
    />
    <path
      className="cubespark-1"
      d="M382.32,448.59a1.52,1.52,0,0,0-.44-1.06,1.5,1.5,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.48,1.48,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-.44A1.48,1.48,0,0,0,382.32,448.59Z"
    />
    <path
      className="cubespark-1"
      d="M426.6,424a1.5,1.5,0,1,0,2,.66A1.5,1.5,0,0,0,426.6,424Z"
    />
    <path
      className="cubespark-1"
      d="M473.73,400.48a1.48,1.48,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.52,1.52,0,0,0,.44,1.06,1.48,1.48,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,473.73,400.48Z"
    />
    <circle className="cubespark-1" cx="473.73" cy="350.13" r="1.5" />
    <path
      className="cubespark-1"
      d="M473.73,296.77a1.48,1.48,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.52,1.52,0,0,0,.44,1.06,1.48,1.48,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,473.73,296.77Z"
    />
    <path
      className="cubespark-1"
      d="M426.6,320.24a1.5,1.5,0,1,0,2,.66A1.5,1.5,0,0,0,426.6,320.24Z"
    />
    <path
      className="cubespark-1"
      d="M381.88,343.82a1.5,1.5,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.48,1.48,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-.44,1.48,1.48,0,0,0,.44-1.06A1.52,1.52,0,0,0,381.88,343.82Z"
    />
    <circle className="cubespark-1" cx="380.82" cy="396.74" r="1.5" />
    <path
      className="cubespark-1"
      d="M98,393.93a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.51,1.51,0,0,0,2.13,0,1.53,1.53,0,0,0,.43-1.06,1.55,1.55,0,0,0-.43-1.06A1.52,1.52,0,0,0,98,393.93Z"
    />
    <path
      className="cubespark-1"
      d="M134.72,412.05a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,134.72,412.05Z"
    />
    <path
      className="cubespark-1"
      d="M170.09,429.85a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="170.09" cy="391.39" r="1.5" />
    <path
      className="cubespark-1"
      d="M170.09,349.93a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M134.72,332.13a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,134.72,332.13Z"
    />
    <path
      className="cubespark-1"
      d="M98,314a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.51,1.51,0,0,0,2.13,0,1.53,1.53,0,0,0,.43-1.06,1.55,1.55,0,0,0-.43-1.06A1.52,1.52,0,0,0,98,314Z"
    />
    <circle className="cubespark-1" cx="98.02" cy="355.47" r="1.5" />
    <path
      className="cubespark-1"
      d="M280.84,99.23a1.5,1.5,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06,1.5,1.5,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06A1.52,1.52,0,0,0,280.84,99.23Z"
    />
    <path
      className="cubespark-1"
      d="M245.72,117a1.5,1.5,0,1,0-2-.66A1.5,1.5,0,0,0,245.72,117Z"
    />
    <path
      className="cubespark-1"
      d="M209.25,132.15a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M246,150.27a1.5,1.5,0,1,0,.67,2A1.5,1.5,0,0,0,246,150.27Z"
    />
    <path
      className="cubespark-1"
      d="M281.31,171.08a1.5,1.5,0,0,0,1.06-.44,1.51,1.51,0,0,0,0-2.13,1.53,1.53,0,0,0-1.06-.43,1.55,1.55,0,0,0-1.06.43,1.51,1.51,0,0,0,1.06,2.57Z"
    />
    <path
      className="cubespark-1"
      d="M316.43,150.27a1.5,1.5,0,0,0,1.35,2.69,1.5,1.5,0,1,0-1.35-2.69Z"
    />
    <path
      className="cubespark-1"
      d="M354.4,133.65a1.52,1.52,0,0,0-.44-1.06,1.48,1.48,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.48,1.48,0,0,0,1.06-.44A1.5,1.5,0,0,0,354.4,133.65Z"
    />
    <path
      className="cubespark-1"
      d="M316.2,117a1.5,1.5,0,1,0-.67-2A1.51,1.51,0,0,0,316.2,117Z"
    />
    <path
      className="cubespark-1"
      d="M391.48,429.85a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M426.6,412.05a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,426.6,412.05Z"
    />
    <path
      className="cubespark-1"
      d="M463.07,393.93a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,463.07,393.93Z"
    />
    <circle className="cubespark-1" cx="463.07" cy="355.47" r="1.5" />
    <path
      className="cubespark-1"
      d="M463.07,314a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,463.07,314Z"
    />
    <path
      className="cubespark-1"
      d="M426.6,332.13a1.5,1.5,0,1,0,2,.67A1.5,1.5,0,0,0,426.6,332.13Z"
    />
    <path
      className="cubespark-1"
      d="M391.48,349.93a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <circle className="cubespark-1" cx="391.48" cy="391.39" r="1.5" />
    <path
      className="cubespark-1"
      d="M110.25,388.88a1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44A1.5,1.5,0,0,0,110.25,388.88Z"
    />
    <path
      className="cubespark-1"
      d="M134.72,400.15a1.5,1.5,0,1,0,.68,2A1.51,1.51,0,0,0,134.72,400.15Z"
    />
    <path
      className="cubespark-1"
      d="M159.36,415.61a1.5,1.5,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06,1.5,1.5,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06A1.52,1.52,0,0,0,159.36,415.61Z"
    />
    <circle className="cubespark-1" cx="159.36" cy="386.05" r="1.5" />
    <path
      className="cubespark-1"
      d="M157.86,358a1.5,1.5,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,0-3,1.52,1.52,0,0,0-1.06.44A1.5,1.5,0,0,0,157.86,358Z"
    />
    <path
      className="cubespark-1"
      d="M134.72,344a1.5,1.5,0,1,0,.68,2A1.5,1.5,0,0,0,134.72,344Z"
    />
    <path
      className="cubespark-1"
      d="M109.81,331.69a1.52,1.52,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06A1.52,1.52,0,0,0,109.81,331.69Z"
    />
    <circle className="cubespark-1" cx="108.75" cy="360.82" r="1.5" />
    <path
      className="cubespark-1"
      d="M280.91,109.92a1.5,1.5,0,0,0,1.06-.44,1.48,1.48,0,0,0,.44-1.06,1.52,1.52,0,0,0-.44-1.06,1.5,1.5,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.48,1.48,0,0,0,.44,1.06A1.52,1.52,0,0,0,280.91,109.92Z"
    />
    <path
      className="cubespark-1"
      d="M256.44,122.38a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,256.44,122.38Z"
    />
    <path
      className="cubespark-1"
      d="M231.69,134.71a1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5A1.52,1.52,0,0,0,231.69,134.71Z"
    />
    <path
      className="cubespark-1"
      d="M256.6,144.92a1.51,1.51,0,0,0-2,.68,1.5,1.5,0,1,0,2-.68Z"
    />
    <path
      className="cubespark-1"
      d="M281.24,160.38a1.5,1.5,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06,1.5,1.5,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06A1.52,1.52,0,0,0,281.24,160.38Z"
    />
    <path
      className="cubespark-1"
      d="M305.71,144.93a1.5,1.5,0,1,0,2,.66A1.5,1.5,0,0,0,305.71,144.93Z"
    />
    <path
      className="cubespark-1"
      d="M330.46,132.59a1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.48,1.48,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.52,1.52,0,0,0-.44-1.06,1.48,1.48,0,0,0-1.06-.44A1.52,1.52,0,0,0,330.46,132.59Z"
    />
    <path
      className="cubespark-1"
      d="M305.55,122.38a1.5,1.5,0,1,0-.67-2A1.5,1.5,0,0,0,305.55,122.38Z"
    />
    <path
      className="cubespark-1"
      d="M403.63,414.11a1.52,1.52,0,0,0-.44-1.06,1.5,1.5,0,0,0-1.06-.44,1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M428,402.84a1.5,1.5,0,1,0-2-.67A1.5,1.5,0,0,0,428,402.84Z"
    />
    <path
      className="cubespark-1"
      d="M453.48,389.94a1.5,1.5,0,0,0,.44-1.06,1.51,1.51,0,0,0-2.57-1.06,1.55,1.55,0,0,0-.43,1.06,1.49,1.49,0,0,0,1.5,1.5A1.52,1.52,0,0,0,453.48,389.94Z"
    />
    <circle className="cubespark-1" cx="452.42" cy="360.82" r="1.5" />
    <path
      className="cubespark-1"
      d="M453.48,331.69a1.51,1.51,0,0,0-2.13,0,1.55,1.55,0,0,0-.43,1.06,1.49,1.49,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06A1.52,1.52,0,0,0,453.48,331.69Z"
    />
    <path
      className="cubespark-1"
      d="M426.6,344a1.5,1.5,0,1,0,2,.66A1.5,1.5,0,0,0,426.6,344Z"
    />
    <path
      className="cubespark-1"
      d="M403.19,356.92a1.5,1.5,0,0,0-1.06-.44,1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0,0,2.12,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-2.56Z"
    />
    <circle className="cubespark-1" cx="402.13" cy="386.05" r="1.5" />
    <path
      className="cubespark-1"
      d="M119.47,380.83a1.5,1.5,0,0,0-1.5,1.5,1.48,1.48,0,0,0,.44,1.06,1.5,1.5,0,0,0,2.56-1.06,1.55,1.55,0,0,0-.43-1.06A1.52,1.52,0,0,0,119.47,380.83Z"
    />
    <path
      className="cubespark-1"
      d="M148.64,395.37a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M148.64,363a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,148.64,363Z"
    />
    <path
      className="cubespark-1"
      d="M119.47,348.49A1.5,1.5,0,0,0,118,350a1.5,1.5,0,0,0,.44,1.06,1.51,1.51,0,0,0,2.13,0A1.53,1.53,0,0,0,121,350a1.55,1.55,0,0,0-.43-1.06A1.52,1.52,0,0,0,119.47,348.49Z"
    />
    <path
      className="cubespark-1"
      d="M281,120.62a1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.48,1.48,0,0,0-.44-1.06,1.5,1.5,0,0,0-1.06-.44,1.52,1.52,0,0,0-1.06.44,1.48,1.48,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5Z"
    />
    <path
      className="cubespark-1"
      d="M252,132.15a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M281.17,149.69a1.5,1.5,0,0,0,1.06-.44,1.52,1.52,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5,1.52,1.52,0,0,0-1.06.44,1.5,1.5,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5Z"
    />
    <path
      className="cubespark-1"
      d="M310.14,132.15a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,310.14,132.15Z"
    />
    <path
      className="cubespark-1"
      d="M412.79,395.37a1.5,1.5,0,0,0-1.06.44,1.52,1.52,0,0,0-.44,1.06,1.5,1.5,0,0,0,1.5,1.5,1.52,1.52,0,0,0,1.06-.44,1.5,1.5,0,0,0,.44-1.06,1.5,1.5,0,0,0-1.5-1.5Z"
    />
    <path
      className="cubespark-1"
      d="M441.76,380.83a1.5,1.5,0,0,0-1.5,1.5,1.48,1.48,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-.44,1.48,1.48,0,0,0,.44-1.06,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,441.76,380.83Z"
    />
    <path
      className="cubespark-1"
      d="M441.76,348.49a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,.44,1.06,1.52,1.52,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.52,1.52,0,0,0-.44-1.06A1.5,1.5,0,0,0,441.76,348.49Z"
    />
    <path
      className="cubespark-1"
      d="M412.79,363a1.5,1.5,0,0,0-1.5,1.5,1.52,1.52,0,0,0,.44,1.06,1.5,1.5,0,0,0,1.06.44,1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-.44-1.06A1.52,1.52,0,0,0,412.79,363Z"
    />
  </SVG>
)

export default CubeSpark

// ___________________________________________________________________

const SVG = styled(motion.svg)<{ fill?: string }>`
  .cubespark-1 {
    fill: ${(p) => (p.fill ? p.fill : theme.colors.primary)};
  }
`
